import type { LinksFunction, LoaderFunction, Session } from '@remix-run/node';
import { json } from '@remix-run/node';
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix';
import '~/stylesheets/fonts.scss';
import '~/stylesheets/global.scss';
import {
  isRouteErrorResponse,
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useLocation,
  useRouteError,
} from '@remix-run/react';
import type { SessionData } from './types/sessionData';
import {
  commitSession,
  destroySession,
  decodeTokenFromString,
  tokenStringFromCookieHeader,
  getLocalFromCookieHeader,
  getSession,
  SessionFlashData,
  visitorUUIDStringFromCookieHeader,
} from './services/session.server';
import { DEFAULT_LANGUAGE, useChangeLang, useLanguageParam } from './config/i18n';

import ErrorPage from '~/components/pages/error/ErrorPage';
import { useEffect } from 'react';
import * as gtag from '~/hooks/gtags.client';
import { AnalyticsScripts } from '~/components/layouts/AnalyticsScripts';
import { Toast } from './components/common/Toast';
import { popToast } from './components/utils/sessions/toast';
import mixpanelTrack from '~/hooks/useMixPanel';
import { refreshJwtTokenIfIncomplete } from '~/components/utils/verification.server';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';
import Flash from '~/components/common/Flash';

export const links: LinksFunction = () => [{ rel: 'preconnect', href: 'https://fonts.googleapis.com' }];

function setCookiesFromTrackingParams(url: URL, responseHeaders: Headers) {
  const gclid = url.searchParams.get('gclid');
  const utm_source = url.searchParams.get('utm_source');

  if (gclid || utm_source) {
    if (gclid) {
      responseHeaders.append(
        'Set-Cookie',
        `gclid=${gclid}; Path=/; Max-Age=3600; SameSite=Strict; ${
          process.env.NODE_ENV === 'production' ? 'Secure' : ''
        };`,
      );
    }

    if (utm_source) {
      responseHeaders.append(
        'Set-Cookie',
        `utm_source=${utm_source}; Path=/; Max-Age=3600; SameSite=Strict; ${
          process.env.NODE_ENV === 'production' ? 'Secure' : ''
        };`,
      );
    }
  }
}

function setVisitorUUIDCookie(responseHeaders: Headers) {
  const visitorUUID = uuidv4();
  responseHeaders.append(
    'Set-Cookie',
    `__fo-visitor-uuid=${visitorUUID}; Path=/; Max-Age=630720000; SameSite=Strict; ${
      process.env.NODE_ENV === 'production' ? 'Secure' : ''
    };`,
  );
}

async function updateSession(
  session: Session<SessionData, SessionFlashData>,
  tokenDecoded: SessionData | null,
  responseHeaders: Headers,
) {
  let updatedSession = false;
  if (tokenDecoded) {
    Object.keys(tokenDecoded).forEach((key) => {
      const sessionValue = session.get(key as keyof SessionData);
      const tokenValue = tokenDecoded[key as keyof SessionData];
      if (sessionValue?.toString() != tokenValue?.toString()) {
        // console.log('SESSION DIFF', sessionValue, '!==', tokenValue);
        session.set(key as keyof SessionData, tokenValue);
        updatedSession = true;
      }
    });
  }

  if (updatedSession) {
    responseHeaders.append('Set-Cookie', tokenDecoded ? await commitSession(session) : await destroySession(session));
  }
}

export const loader: LoaderFunction = async ({ request }) => {
  const cookieHeader = request.headers.get('Cookie');
  const locale = getLocalFromCookieHeader(cookieHeader || '') || DEFAULT_LANGUAGE;

  let existingToken = tokenStringFromCookieHeader(cookieHeader || '');
  existingToken = existingToken && (await refreshJwtTokenIfIncomplete(existingToken));
  const tokenDecoded = existingToken ? decodeTokenFromString(existingToken) : null;
  const session = await getSession(cookieHeader);

  const responseHeaders = new Headers();
  await updateSession(session, tokenDecoded, responseHeaders);

  const url = new URL(request.url);
  setCookiesFromTrackingParams(url, responseHeaders);

  if (!tokenDecoded && visitorUUIDStringFromCookieHeader(cookieHeader || '') === null) {
    setVisitorUUIDCookie(responseHeaders);
  }

  let flashAlert: string | null = null;
  if (url.searchParams.get('alert')) {
    flashAlert = url.searchParams.get('alert');
  }
  let flashNotice: string | null = null;
  if (url.searchParams.get('notice')) {
    flashNotice = url.searchParams.get('notice');
  }

  const { toast } = await popToast(request, responseHeaders);
  return json(
    {
      gtmContainerId:
        import.meta.env.VITE_ENVIRONMENT === 'production' ? import.meta.env.VITE_GTM_CONTAINER_ID : undefined,
      ga4TagId: import.meta.env.VITE_ENVIRONMENT === 'production' ? import.meta.env.VITE_GA4_TAG_ID : undefined,
      userSession: tokenDecoded ? session.data : null,
      locale,
      environment: import.meta.env.VITE_ENVIRONMENT,
      toast,
      flashAlert,
      flashNotice,
    },
    {
      headers: responseHeaders,
    },
  );
};

const App = () => {
  const location = useLocation();
  const { environment, gtmContainerId, ga4TagId, toast, flashAlert, flashNotice } = useLoaderData<typeof loader>();
  // useGoogleTagManager(environment);
  const lang = useLanguageParam();
  //this done by rails !! keep to use when rails become a api
  // if (!lang) {
  //   redirect(`/${locale}`);
  // }
  const { t } = useTranslation();

  useEffect(() => {
    if (gtmContainerId?.length) {
      gtag.pageview(location.pathname);
    }
  }, [location, gtmContainerId]);

  useChangeLang(lang);

  return (
    <html lang={lang}>
      <head>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1"
        />
        <Meta />
        <Links />
        <script
          defer={true}
          src="https://cdn.jsdelivr.net/npm/@popperjs/core@2/dist/umd/popper.min.js"
          crossOrigin="anonymous"
        ></script>
        <script
          defer={true}
          src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.2/dist/js/bootstrap.min.js"
          crossOrigin="anonymous"
        ></script>
      </head>
      <body>
        <Toast
          toast={toast}
          onToastClicked={() => {
            if (toast?.type === 'favouriteAdd') {
              mixpanelTrack('D.favorite_toast_click');
            }
          }}
        />
        {flashAlert && (
          <Flash
            message={t(flashAlert)}
            type="alert"
          />
        )}
        {flashNotice && (
          <Flash
            message={t(flashNotice)}
            type="notice"
          />
        )}
        <AnalyticsScripts
          gtmContainerId={gtmContainerId}
          ga4TagId={ga4TagId}
        />
        <script
          dangerouslySetInnerHTML={{
            __html: `window.ENVIRONMENT = ${JSON.stringify(environment)}`,
          }}
        />
        <Outlet />
        <ScrollRestoration
          getKey={(location) => {
            return location.pathname;
          }}
        />
        <Scripts />
      </body>
    </html>
  );
};

export function ErrorBoundary() {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error)) {
    return ErrorPage({ statusCode: error.status, message: error.statusText });
  } else if (error instanceof Error) {
    return ErrorPage({ statusCode: 500, message: error.message + '\n' + error.stack });
  } else {
    return <h1>Unknown Error</h1>;
  }
}

export default withSentry(App);
