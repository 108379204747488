import type { ToastData } from '~/types/toast';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

declare const bootstrap: any;

const positions: Record<string, string> = {
  'top-left': 'top-0 start-0',
  'top-right': 'top-0 end-0',
  'bottom-left': 'bottom-0 start-0',
  'bottom-right': 'bottom-0 end-0',
};

const getPosition = (position: keyof typeof positions) =>
  (position && positions[position]) || positions['bottom-right'];

export const Toast = ({ toast, onToastClicked }: { toast: ToastData; onToastClicked: () => void }) => {
  const { t } = useTranslation();
  const [mobileNavVisible, setMobileNavVisible] = useState(true);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].intersectionRatio <= 0.9) {
          setMobileNavVisible(false);
        } else {
          setMobileNavVisible(true);
        }
      },
      { threshold: 0.9 },
    );
    const mobileNavElement = document.getElementById('bottom-menu');
    if (mobileNavElement) observer.observe(mobileNavElement);

    if (toast) {
      const toastEl = document.getElementById('liveToast');
      const toastInstance = bootstrap.Toast.getOrCreateInstance(toastEl);
      toastInstance.show();
      toastEl?.addEventListener('hidden.bs.toast', function () {
        if (mobileNavElement) observer.unobserve(mobileNavElement);
      });
    }
  }, [toast]);

  if (!toast) {
    return null;
  }

  const { image, type, translationKey, interpolation, position } = toast;

  return (
    <div
      className={`fo-toast toast-container position-fixed p-3 ${
        mobileNavVisible ? 'mb-16' : 'mb-0'
      } mb-md-4 ${getPosition(position || '')}`}
      onClick={onToastClicked}
    >
      <div
        id="liveToast"
        className="toast"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-body d-flex flex-columns bg-white border-radius-sm">
          {image && (
            <div className="image-container">
              <img
                src={image}
                alt={type}
              />
            </div>
          )}
          <div className="ms-3 text-1 d-flex align-items-center">
            <span dangerouslySetInnerHTML={{ __html: t(translationKey, interpolation) }} />
          </div>
        </div>
      </div>
    </div>
  );
};
