type TProps = {
  message: string;
  type?: FlashType;
};

export type FlashType = 'notice' | 'alert';

const Flash = ({ message, type = 'notice' }: TProps) => {
  function cssForType(type: string) {
    switch (type) {
      case 'alert':
        return 'color-error-500 shadow-sm bg-error-100';
      case 'notice':
      default:
        return 'fo-border-accent-500 color-accent-500 shadow-sm bg-light-grey';
    }
  }

  return (
    <div className="position-absolute mt-24 w-100">
      <div className="mx-12">
        <div className="text-center">
          <div className={`fo-border mx-auto px-8 py-4 d-inline-block border-black rounded ${cssForType(type)}`}>
            <span className="fo-display-4">{message}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Flash;
