import { useLocation } from '@remix-run/react';

type ErrorProps = {
  statusCode: number;
  message: string;
};

const ErrorPage = ({ statusCode, message }: ErrorProps) => {
  const location = useLocation();

  return (
    <>
      {/*<Links />*/}
      <div className="mt-4">
        <div className="container">
          <div className="">
            <div className="-noTab">
              <div className="pb-4 mb-12 border-bottom border-4">
                <h1 className="d-none d-lg-block h1">
                  Error {statusCode}{' '}
                  <span className="color-grey-500">on page {location.pathname + location.search}</span>
                </h1>
              </div>
            </div>
            <div className="row justify-content-center text-center">
              <div className="mb-4">Time: {new Date().toISOString()}</div>
              <div className="col-6">
                <img
                  src="/media/error.svg"
                  alt=""
                  className="img-fluid"
                />
              </div>
              <div className="col-12">
                <pre className="fo-textXl">{message}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ErrorPage;
