import { useEffect, useRef } from 'react';

export function AnalyticsScripts({ gtmContainerId, ga4TagId }: { gtmContainerId: string; ga4TagId: string }) {
  const gtmScriptRef = useRef<HTMLScriptElement | null>(null);
  const ga4ScriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (gtmContainerId && gtmContainerId.length) {
      const gtmScript = document.createElement('script');
      gtmScript.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({
              'gtm.start': new Date().getTime(),
              event: 'gtm.js'
          });
          var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s),
              dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src =
              'https://metrics.fiveoffices.com/33hg7brkbe2g07t.js?aw='+i.replace(/^GTM-/, '')+dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${gtmContainerId}');`;

      gtmScriptRef.current = gtmScript;
      document.head?.appendChild(gtmScript);
    }

    return () => {
      if (gtmScriptRef.current && document.head) {
        document.head.removeChild(gtmScriptRef.current);
      }
    };
  }, [gtmContainerId]);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    if (ga4TagId && ga4TagId.length) {
      const ga4Script = document.createElement('script');
      ga4Script.innerHTML = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());        
        gtag('config', '${ga4TagId}');`;

      ga4ScriptRef.current = ga4Script;
      document.head?.appendChild(ga4Script);
    }

    return () => {
      if (ga4ScriptRef.current && document.head) {
        document.head.removeChild(ga4ScriptRef.current);
      }
    };
  }, [ga4TagId]);

  if (typeof window === 'undefined') {
    return null;
  }

  return (
    <>
      {gtmContainerId && (
        <noscript
          dangerouslySetInnerHTML={{
            __html: `
            <iframe src="https://metrics.fiveoffices.com/ns.html?id=${gtmContainerId}"
                    height="0" width="0" style="display:none;visibility:hidden"></iframe>
        `,
          }}
        />
      )}
    </>
  );
}
